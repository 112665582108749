import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import { unmountComponentAtNode } from "react-dom";
import {base, appDatabasePrimaryFunctions} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import { validateEmail } from '../utils/HelpfulFunction';
import AnswerItemsComponent from '../../components/small_components/AnswerItemsComponent';
import Confetti from 'react-confetti';
import ReactPlayer from 'react-player';
import { getDistance } from 'geolib';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';
import '../../styles/css/modalstyles.css';
import posed, {PoseGroup} from "react-pose";

const Trans = posed.div({
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: ({ delta }) => - delta * 100 + 'vw' }
})

class Main_App extends Component {
    constructor(props) {
        super(props);
        let userEmail;
        try {
          userEmail = sessionStorage.getItem('userEmail') || sessionStorage.getItem('tempToken') || false;
        } catch (e) {
          userEmail = false
        }
        this.state = {
          userEmail: userEmail,
          alerted: false,
          gameActive: false,
          fullName: '',
          seenVideo: false,
          phone: '',
          zip: '',
          tenantRules: {},
          question: 0,
          width: 0,
          height: 0,
          questions: [],
          surveyQuestions: [],
          questionAnswered: false,
          formFilledOut: false,
          videoDuration: "0:00",
          userAnswers: [],
          alreadyUpdate: "",
          currentGameId: null,
          currentGameTime: false,
          currentResponseArray: [],
          startConfetti: false,
          rotationStarted: false,
          locationChecking: true,
          optedIntoEmail: {},
          tierMessaging: [],
          startAnimating: false,
          otherInput: "",
          formQuestions: []
        };
        this.logout = this.logout.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element');
      const target = document.getElementById('react-confirm-alert');
      if(target){
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg');
      if(svg){
        svg.parentNode.removeChild(svg);
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentDidMount() {
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string"){
            this.logUserActivity(key);
            this.removeReactAlert();
            this.setState({
              currentGameId:key,
              startConfetti: false
            }, ()=> {
              this.currentGameFormFilledRef = base.listenTo('currentGameFormFilled/'+ this.state.currentGameId +'/' +base64EncodedEmail, {
                context: this,
                state: 'formFilledOut',
                then(response){
                  if(response.name){
                    document.getElementById('more-info-form').style.display = "none";
                    document.getElementById('thanks-for-playing-section').style.display = ""
                  }
                }
              })
            })
          }
        }
      });

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(userEmail && userEmail.indexOf('@') === -1){
        base64EncodedEmail = userEmail
      }

      this.gameActiveRef = base.bindToState('currentGame/active', {
        context: this,
        state: 'gameActive',
      })

      this.gameStartTimeRef = base.bindToState('currentGame/timeStamp', {
        context: this,
        state: 'currentGameTime',
      })

      this.rotationStartedRef = base.bindToState('currentGame/rotate', {
        context: this,
        state: 'rotationStarted',
      })

      this.endTriviaVideoLinkRef = base.bindToState('currentGame/endTriviaVideoLink', {
        context: this,
        state: 'endTriviaVideoLink',
      })

      this.gameTimingRef = base.bindToState('currentGame/gameTiming', {
        context: this,
        state: 'gameTiming',
      })

      this.tierMessagingRef = base.bindToState('tierMessaging', {
        context: this,
        state: 'tierMessaging',
      });

      this.questionRef = base.bindToState('currentGame/questions', {
        context: this,
        state: 'questions',
        asArray: true
      });

      this.surveyQuestionsRef = base.bindToState('currentGame/surveyQuestions', {
        context: this,
        state: 'surveyQuestions',
        asArray: true
      })

      this.gameOverRef = base.bindToState('currentGame/answered', {
        context: this,
        state: 'gameOver'
      });

      this.userAnswersRef = base.bindToState("userAnswers", {
        context: this,
        state: 'userAnswers',
        asArray: true
      });

      this.formQuestionsRef = base.bindToState("formQuestions", {
        context: this,
        state: 'formQuestions',
        asArray: true
      });

      this.prizesWonRef = base.listenTo('prizeWon/'+base64EncodedEmail, {
        context: this,
        then(winnerPrize){
         if(this.state.gameTiming !== "immediate"){
           if(winnerPrize === true){
             this.showGameOverMessage(true)
           } else if(winnerPrize === false) {
             this.showGameOverMessage(false)
           }
         }
        }
      });

      this.currentResponseArrayRef = base.listenTo('userAnswers/'+base64EncodedEmail, {
        context: this,
        state: 'currentResponseArray',
        asArray: true,
        then(arrayOfAnswers){
          if(arrayOfAnswers.length === 0 && this.props.variables && this.props.variables.collectDistance && this.props.variables.formattedAddress && this.props.variables.acceptableDistance){
            this.checkUsersLocation();
          } else {
            let numberOfAnswers = arrayOfAnswers.length;
            let questions = this.state.questions || [];
            const surveyQuestions = this.state.surveyQuestions || [];
            const totalQuestions = surveyQuestions.concat(questions);
            let questionAnswered = false;
            let hasVideoUrl = false;
            if(numberOfAnswers > 0){
              numberOfAnswers = numberOfAnswers - 1;
              if(numberOfAnswers === totalQuestions.length){
                questionAnswered = true;
                if(this.state.gameTiming === "immediate"){
                    this.startLocalAnswers()
                }
              } else if(numberOfAnswers > 0 && numberOfAnswers === surveyQuestions.length) {
                const tenantVariables = this.props.variables || {};
                const stringConstants = this.props.stringConstants || {};
                confirmAlert({
                  title: tenantVariables.surveyQuestionHeader || "Thanks for answering!",
                  variables: tenantVariables,
                  message: tenantVariables.surveyQuestionText || "Let's take a short quiz to see if you win a prize!",
                  buttons: [
                    {
                      label: stringConstants.OKTEXT,
                      onClick: () => {
                        hasVideoUrl = !!totalQuestions[numberOfAnswers].videoLink;
                        this.setState({
                          modal: hasVideoUrl
                        })
                      }
                    }
                  ]
                });
              } else {
                hasVideoUrl = !!totalQuestions[numberOfAnswers].videoLink;
              }
            } else {
             if(numberOfAnswers === 0 && surveyQuestions.length > 0) {
               const tenantVariables = this.props.variables || {};
               const stringConstants = this.props.stringConstants || {};
               confirmAlert({
                 title: tenantVariables.surveyQuestionStartHeader || "Survey Questions",
                 variables: tenantVariables,
                 message: tenantVariables.surveyQuestionStartBody || "There are no wrong answers!",
                 buttons: [
                   {
                     label: stringConstants.OKTEXT
                   }
                 ]
               });
             } else if(totalQuestions[numberOfAnswers]){
                hasVideoUrl = !!totalQuestions[numberOfAnswers].videoLink;
              } else {
                hasVideoUrl = false
              }
            }
            let startAnimating = true
            if(this.state.question !== numberOfAnswers){
              startAnimating = false
            }
            this.setState({
              questionAnswered: questionAnswered,
              question: numberOfAnswers,
              currentResponseArray: arrayOfAnswers,
              locationChecking: false,
              modal: hasVideoUrl,
              startAnimating: startAnimating
            }, ()=>{
              this.setState({
                startAnimating: true
              })
            })
          }
        }
      });

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0);
      this.props.loadPage();
    }

    onEnded(){
      if(this.state.questionAnswered) {
        this.setState({
          immediateAnswersShow: true
        })
        this.getPrize()
      }
      this.setState({
        modal: false,
        startConfetti: false
      })
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    componentWillUnmount() {
      base.removeBinding(this.rotationStartedRef);
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.tierMessagingRef);
      base.removeBinding(this.currentResponseArrayRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.questionRef);
      base.removeBinding(this.userAnswersRef);
      base.removeBinding(this.prizesWonRef);
      base.removeBinding(this.gameStartTimeRef);
      base.removeBinding(this.gameActiveRef);
      base.removeBinding(this.gameTimingRef);
      base.removeBinding(this.gameOverRef);
      base.removeBinding(this.endTriviaVideoLinkRef);
      base.removeBinding(this.surveyQuestionsRef);
      base.removeBinding(this.formQuestionsRef);
      if(this.currentGameFormFilledRef){
        base.removeBinding(this.currentGameFormFilledRef);
      }
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout(){
      sessionStorage.removeItem('verifiedAge');
      sessionStorage.removeItem('userEmail');
      sessionStorage.removeItem('birthday');
      sessionStorage.removeItem('locationPermissions');
      sessionStorage.removeItem('id_token');
      this.setState({
        userEmail:false
      })
    }

  setUpQuestionsWithAnswerCount(userAnswers, questions){
      let finalQuestionTally = {};
      let questionIdArray = [];
      for(let questionIndex in questions){
        let question = questions[questionIndex];
        let questionId = question.id;
        finalQuestionTally[questionId] = {};
        finalQuestionTally[questionId].totalPicks = 0;
        finalQuestionTally[questionId].answers = {};
        let questionAnswers = question.answers;
        for(let answerIndex in questionAnswers){
          let answer = questionAnswers[answerIndex];
          if(this.state.immediateAnswersShow && this.state.gameTiming === "immediate"){
            answer.shown = true
          } else if(this.state.gameTiming === "immediate"){
            answer.shown = false
          }
          let answerId = answer.id;
          finalQuestionTally[questionId].answers[answerId] = 0;
        }
        questionIdArray.push(questions[questionIndex].id);
      }
      if(userAnswers.length > 0) {
        for(let answerIndex in userAnswers) {
          let answerObject = userAnswers[answerIndex];
          for(let questionIndex in questionIdArray){
            let questionId = questionIdArray[questionIndex];
            if(answerObject[questionId]){
              finalQuestionTally[questionId].totalPicks++;
              let answerId =  answerObject[questionId];
              finalQuestionTally[questionId].answers[answerId]++;
            };
          }
        }
      }
      return finalQuestionTally;
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getLocation(){
      const stringConstants = this.props.stringConstants;
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(stringConstants.GEOLOCATIONNOTSUPPORTED);
        } else {
          const toCheckLatitude = this.props.variables.latitude || 51.525;
          const toCheckLongitude = this.props.variables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                  latitude: toCheckLatitude,
                  longitude: toCheckLongitude,
                }))
              },
              (err) => {
                if(err.message === "User denied Geolocation"){
                  reject(stringConstants.NEEDBROWSERPERMISSION);
                } else {
                  console.log(err.message);
                  reject(stringConstants.UNKNOWNERROROCCURED);
                }
              }
          );
        }
      })
    }

    startLocalAnswers(){
      const stringConstants = this.props.stringConstants || {};
      confirmAlert({
        title: stringConstants.FINISHEDTEXT,
        variables: this.props.variables,
        message: stringConstants.YOUANSWEREDALLQUESTIONSTEXT,
        buttons: [
          {
            label: stringConstants.OKTEXT,
            onClick: () => {
              if(this.state.endTriviaVideoLink){
                this.setState({modal:true})
              } else {
                this.setState({
                  immediateAnswersShow: true
                })
                this.getPrize()
              }
            }
          }
        ]
      });
    }

    getPrize() {
      const stringConstants = this.props.stringConstants || {};
      const userResponse = {};
      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(userEmail && userEmail.indexOf('@') === -1){
        base64EncodedEmail = userEmail
      }
      const vm = this;
      userResponse['userInfo'] = base64EncodedEmail;
      userResponse['tenantVariables'] = this.props.variables || {};
      let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/immediatePrizeCheck`;
      if(process.env.NODE_ENV === "development"){
        url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/immediatePrizeCheck`;
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userResponse),
      }).then(res => {
        vm.setState({
          loading: false
        })
        Promise.resolve(res.json()).then(function (value) {
          console.log(value)
          if(value.error){
            confirmAlert({
              title: stringConstants.ERRORTEXT,
              variables: this.props.variables,
              message: stringConstants.SOMEERROROCCURED,
              buttons: [
                {
                  label: stringConstants.OKTEXT,
                  onClick: () => {
                    vm.startLocalAnswers();
                  }
                }
              ]
            });
          } else {
            if(value.prizeWon){
              vm.showGameOverMessage(true)
            } else {
              vm.showGameOverMessage(false)
            }
          }
        })
      })
    }

    getLocationPermission(){
      const stringConstants = this.props.stringConstants || {};
      const locationErrorTitle = stringConstants.LOCATIONERRORHEADER;
      this.getLocation().then(distance_meters => {
        sessionStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
        if(!this.props.variables.canPlayOutside && distance_meters <= allowed_distance){
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false
          });
          this.logUserActivity(this.state.currentGameId);
        } else if(this.props.variables.canPlayOutside && distance_meters > allowed_distance) {
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false
          });
          this.logUserActivity(this.state.currentGameId);
        } else {
          this.setState({
            modal:false,
            loading:false
          });
          let locationErrorMessage = "Too far from game area to participate!";
          if(this.props.variables.canPlayOutside){
            locationErrorMessage = "Too close game area to participate!";
          }
          if(this.props.variables.notAcceptableLocationMessage){
            locationErrorMessage = this.props.variables.notAcceptableLocationMessage;
          }
          let notAcceptableLocationHeader = locationErrorTitle;
          if(this.props.variables.notAcceptableLocationHeader){
            notAcceptableLocationHeader = this.props.variables.notAcceptableLocationHeader;
          }
          confirmAlert({
            title: notAcceptableLocationHeader,
            variables: this.props.variables,
            message: locationErrorMessage,
            buttons: [
              {
                label: stringConstants.RETRYTEXT,
                onClick: () => {
                  this.checkUsersLocation()
                }
              }
            ]
          });
        }
      }, error => {
        this.setState({
          modal:false,
          loading:false
        });
        sessionStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
          title: locationErrorTitle,
          variables: this.props.variables,
          message: error,
          buttons: [
            {
              label: stringConstants.RETRYTEXT,
              onClick: () => {
                this.checkUsersLocation()
              }
            }
          ]
        });
      })
    }

    checkUsersLocation(){
      const stringConstants = this.props.stringConstants || {};
      const variables = this.props.variables;
      const locationPermissions = sessionStorage.getItem('locationPermissions');
      if(!this.props.variables.collectDistance) {
        this.setState({locationChecking: false})
      } else if(locationPermissions === "false" || !locationPermissions){
        const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
        const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
        confirmAlert({
          title: locationPermissionsHeader,
          variables: variables,
          message: locationPermissionsBody,
          buttons: [
            {
              label: stringConstants.YESCONFIRMBUTTONTEXT,
              onClick: () => {
                this.setState({
                  loading: true,
                });
                this.getLocationPermission()
              }
            }
          ],
        })
      } else {
        this.setState({
          loading: true,
        });
        this.getLocationPermission()
      }
    }

    showGameOverMessage(gotWinningMessage){
      const currentAnswerArray = this.state.currentResponseArray;
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      const questions = this.state.questions;
      const answers = [];
      for(const y in questions){
        const questionAnswers = questions[y].answers;
        for(const answerIndex in questionAnswers){
          if(currentAnswerArray.indexOf(questionAnswers[answerIndex].id) !== -1){
            questionAnswers[answerIndex].shown =  questions[y].shown;
            answers.push(questionAnswers[answerIndex])
          }
        }
      }
      let count = 0;
      for(const answerIndex in answers){
        const answer = answers[answerIndex];
        if(answer.correct){
          count+=1
        }
      }
      const tierMessages = this.state.tierMessaging[count];
      let headerHeader = tenantVariables.gameOverHeader || "Game Already Ended!";
      let headerMessage = tenantVariables.gameOverBody || "The game already happened, come back next time to play.";
      let messageImage = "";
      if(gotWinningMessage === true){
        headerHeader = tierMessages.winningHeader;
        headerMessage = tierMessages.winningMessage;
        messageImage = tenantVariables.winningMessageImage;
        if(count > 0){
          this.setState({
            startConfetti: true
          })
        }
      } else if (gotWinningMessage === false) {
        headerHeader = tierMessages.winningMissedHeader;
        headerMessage = tierMessages.winningMissedMessage;
      }
      confirmAlert({
        variables: this.props.variables,
        messageImage:messageImage,
        title: headerHeader,
        message: headerMessage,
        buttons: [
          {
            label: stringConstants.OKTEXT,
            onClick: () => {
              this.setState({startConfetti:false})
            }
          }
        ]
      })
    }

    logUserActivity(currentGame){
      const vm = this;
      let userEmailToBase64 = "";
      if(currentGame && this.state.userEmail.indexOf('@') > -1){
        const gameid = currentGame;
        userEmailToBase64 = btoa(this.state.userEmail);
        base.post('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          data:gameid,
          then(err){
            if(!err){
              vm.setState({
                alreadyUpdate: gameid,
              })
              console.log("user game logged!")
            }
          }
        })
      }
    }

    toggle() {
      if(this.state.questionAnswered && this.state.modal) {
        this.setState({
          immediateAnswersShow: true
        })
        this.getPrize()
      }
      this.setState({
        modal: !this.state.modal,
        startConfetti: false
      });
    }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    onClickAnswer(answerClicked, position){
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      let questions = this.state.questions;
      const surveyQuestions = this.state.surveyQuestions;
      questions = surveyQuestions.concat(questions);
      if(typeof this.state.currentGameId === "string" && this.state.currentGameId.length > 0){
          this.logUserActivity(this.state.currentGameId);
      }
      // gridItem.style.backgroundColor = sessionStorage.getItem('primaryColor');
      let gameOverTextHeader = tenantVariables.gameOverHeader || "Answers are up!";
      let gameOverTextBody = tenantVariables.gameOverBody || "Sorry you missed answering before the game ended! Come back next game to play again.";
      let gameTiming = this.state.gameTiming;
      if((this.state.rotationStarted || this.state.gameOver) && gameTiming !== "immediate"){
        confirmAlert({
          variables: tenantVariables,
          title: gameOverTextHeader,
          message: gameOverTextBody,
          buttons: [
            {
              label: stringConstants.OKTEXT,
              // onClick: () => gridItem.style.backgroundColor = "transparent"
            }
          ]
        })
        return
      }

      var answerInfo = {};
      // document.getElementsByClassName("grid")
      // document.getElement react-confirm-alert-button-group button
      var questionId = questions[this.state.question].id;
      answerInfo['answerText'] = answerClicked.answerText;
      answerInfo['position'] = position;
      answerInfo['questionId'] = questionId
      answerInfo['answerId'] = answerClicked.id;
      answerInfo['timeAnswered'] = Date.now();
      answerInfo['email'] = this.state.userEmail;
      if(this.state.userEmail.indexOf('@') > -1){
        answerInfo['email'] = btoa(this.state.userEmail);
      }


      // if(this.state.userEmail.trim() === ""){
      //   alert("This email is strange, please logout and try a different one!")
      //   return;
      // }
      // if(this.state.currentResponse.answerId){
      //   confirmAlert({
      //     title: 'Answer already picked!',
      //     message: "You already have an answer selected so you can't pick another one",
      //     buttons: [
      //       {
      //         label: 'OK',
      //       }
      //     ]
      //   })
      //   return;
      // }
      // this.setState({
      //   currentResponse: answerInfo
      // })
      var base64EncodedEmail = btoa(this.state.userEmail);
      var postToUserAnswers = {};
      // https://stackoverflow.com/questions/37954217/is-the-way-the-firebase-database-quickstart-handles-counts-secure/37956590#37956590
      // one day when you want to refactor this with less data being passed around via user answers check out this article on
      // not a perfect solution since I think it could have a higher failure rate on update but if handled well maybe this makes more sense
      // although the more people voting the more failures it would have so a little counter intuitive
      postToUserAnswers["/userAnswers/" + base64EncodedEmail + "/" + questionId] = answerClicked.id;
      postToUserAnswers["/userAnswers/" + base64EncodedEmail + '/timeStamp'] = new Date().getTime().toString();
      // base.post(`userAnswers/${base64EncodedEmail}/${questionId}`, {
      appDatabasePrimaryFunctions.ref().update(postToUserAnswers, function(error){
        if(!error){
          console.log("Answer Clicked");
          // gridItem.style.backgroundColor = "transparent";
        } else {
          console.log(error)
          confirmAlert({
              variables: tenantVariables,
              title: stringConstants.ERRORTEXT,
              message: error.message,
              buttons: [
                {
                  label: stringConstants.OKTEXT,
                }
              ]
            })
        }
      })
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const frontLogoImage = tenantVariables.frontLogoImage || "";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const playingTextHeader = tenantVariables.playingTextHeader || "Next game starts during";
      const questionTextColor = tenantVariables.questionTextColor || "white";

      return(
        <>
            <div className="hero-text-container">
              <img src={frontLogoImage} className="main-hero-image" alt=""/>
            </div>
            <p style={{display: tenantVariables.playingText ? '' : "none", color:questionTextColor}}>{playingTextHeader}
              <br/>
              <span className="emphasizedText" style={{backgroundColor:primaryColor, color:secondaryColor}}>
                {tenantVariables.playingText}
              </span>
            </p>
        </>
        )
    }

    renderPlayingScreen() {
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      const currentAnswerArray = this.state.currentResponseArray;
      const currentGameTime = this.state.currentGameTime;
      let questions = this.state.questions || [];
      const surveyQuestions = this.state.surveyQuestions || [];
      questions = surveyQuestions.concat(questions);
      let answerList = [];
      let question = {};
      let topLeftImage = tenantVariables.topLeftImage || "";
      let topAnswerImage = tenantVariables.topAnswerImage || "";
      let answers = [];
      let show_percentages = false;
      if (tenantVariables.showFanPercentages === "all") {
        show_percentages = true;
      } else if (tenantVariables.showFanPercentages === "parttime" && this.state.questionAnswered) {
        show_percentages = true;
      }
      const nowInMilli = Date.now();
      const hoursInMillisecnods24 = 86400000;
      let hideAnswerForm = false;
      //Turns off form if more than 24 hours from game time, important for seattle sounders
      if (currentGameTime && (currentGameTime + hoursInMillisecnods24) < nowInMilli && process.env.REACT_APP_FIREBASE_PROJECT_ID === "sounderstrivia") {
        hideAnswerForm = true
      }
      const primaryColor = tenantVariables.primaryColor || "black"
      const secondaryColor = tenantVariables.secondaryColor || "white"
      const sponsorLogo = tenantVariables.sponsorLogo || "";
      const questionTextColor = tenantVariables.fanQuestionColor || "white";
      const myAnswersText = stringConstants.MYANSWERSTEXT || "My Answers"
      let questionCount = [];
      let videoURL = "";
      const questionNumber = this.state.question || 0;
      if (questions.length > 0 && questionNumber < questions.length && this.state.questionAnswered === false) {
        question = questions[questionNumber];
        answerList = questions[questionNumber].answers.sort((a, b) => a.order - b.order);
        questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questions);
        videoURL = questions[questionNumber].videoLink;
      } else if (this.state.questionAnswered === true) {
        videoURL = this.state.endTriviaVideoLink;
        for (const y in this.state.questions) {
          var questionAnswers = this.state.questions[y].answers.sort((a, b) => a.order - b.order);
          for (var answer in questionAnswers) {
            if (currentAnswerArray.indexOf(questionAnswers[answer].id) !== -1) {
              questionAnswers[answer].shown = this.state.questions[y].shown;
              answers.push(questionAnswers[answer])
            }
          }
        }
        questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, this.state.questions)
      }
      if (typeof videoURL === "object") {
        videoURL = ""
      }
      return (
          <>
            <div className="grid-wrapper">
              <div className="creator-awards-logo-playscreen user">
                <img src={topLeftImage} alt=""
                     style={{marginBottom: "0", display: this.state.questionAnswered === false ? "" : "none"}}/>
                <img src={topAnswerImage} alt=""
                     style={{marginBottom: "0", display: this.state.questionAnswered === true ? "" : "none"}}/>
              </div>
              <div className="card" style={{borderRadius: "20px", margin: '0', padding: '0'}}>
                {this.state.startAnimating && this.state.questionAnswered === false &&
                  <PoseGroup animateOnMount={true} delta={1} style={{display: "flex", flexDirection: "column"}}>
                    <Trans key={233}>
                      <div className="top-text">
                        <p className="top-text-mobile" style={{
                          wordWrap: "break-word",
                          fontWeight: 500,
                          color: questionTextColor
                        }}>{question.questionText}</p>
                      </div>
                      <AnswerItemsComponent stringConstants={stringConstants} makeSurveyQuestion={question.makeSurveyQuestion} show_percentages={show_percentages && !question.makeSurveyQuestion} question={question} answers={answerList} questionCount={questionCount} tenantVariables={tenantVariables} onClickAnswer={(item, i) => this.onClickAnswer(item, i)}/>
                    </Trans>
                  </PoseGroup>
                }
                {this.state.questionAnswered === true &&
                  <div className="intro-container-home" style={{marginTop: '20px'}}>
                    <div className="top-text">
                      <p className="top-text-mobile"><span className="emphasizedTextAnswers" style={{backgroundColor: primaryColor, color: secondaryColor}}>{myAnswersText}</span></p>
                    </div>
                    <PoseGroup animateOnMount={true} delta={1} style={{margin: "auto", width: "100%"}}>
                      <Trans key={233}>
                        <AnswerItemsComponent makeSurveyQuestion={question.makeSurveyQuestion} show_percentages={show_percentages && !question.makeSurveyQuestion} answers={answers} questions={this.state.questions}
                                              questionCount={questionCount} tenantVariables={tenantVariables} stringConstants={stringConstants}
                                              onClickAnswer={() => console.log("click")}/>
                      </Trans>
                    </PoseGroup>
                    <div className="container-out" id="more-info-form" style={{marginTop: 10, display: tenantVariables.formHeaderText === "" || hideAnswerForm || !tenantVariables.formHeaderText ? 'none' : 'block'}}>
                      <div className="question-box question-form" style={{padding: '15px'}}>
                        <h4 style={{fontFamily: "Oswald", fontWeight: 700, color: "black"}}>{tenantVariables.formHeaderText}</h4>
                        <form onSubmit={this.handleSubmit} id="user-values-form">
                          {process.env.REACT_APP_FIREBASE_PROJECT_ID === "sounderstrivia" ?
                              <>
                                <div className="input-group">
                                  <input id="fullName" name="fullName" type="text" style={{fontFamily: "Oswald"}}
                                         className="form-control" onChange={this.handleChange} placeholder="Full Name"/>
                                </div>
                                <div className="input-group">
                                  <input id="phone" name="phone" type="tel" style={{fontFamily: "Oswald"}}
                                         className="form-control" onChange={this.handleChange} placeholder="Phone"/>
                                </div>
                                <div className="input-group">
                                  <input id="zip" name="zip" type="text" pattern="[0-9]*" style={{fontFamily: "Oswald"}}
                                         className="form-control" onChange={this.handleChange} placeholder="Zip"/>
                                </div>
                                <div className="checkbox" style={{padding: '5px'}}>
                                  <label className="agree_to_rules_class"><input type="checkbox"
                                                                                 ref="agree_to_rules_regs"/> I Agree To <a
                                      target="_blank" rel="noopener noreferrer"
                                      href={this.state.tenantRules.rulesAndRegsLink}>Rules And Regulations</a></label>
                                </div>
                                <div className="checkbox" style={{padding: '5px'}}>
                                  <label className="want_emails"><input type="checkbox" ref="want_emails"
                                                                        defaultChecked/> I want to receive emails </label>
                                </div>
                              </>
                              :
                              <div style={{color:"black", textAlign: "left"}}>
                                {
                                  this.state.formQuestions.map(function(item, i){
                                      return <div className="checkbox" style={{padding: '5px'}} key={i}>
                                        <label className="want_emails"><input type="checkbox" ref={item.id}/> {item.text}</label>
                                      </div>
                                  })
                                }
                                {/*Other Response needs to be displayed to hide because of ref that will BREAK if you hide using react */}
                                <div style={{display: tenantVariables.otherResponse ? "block":"none"}}>
                                  <div className="checkbox" style={{padding: '5px'}}>
                                    <label className="want_emails"><input type="checkbox" ref="other_checkbox" onClick={()=> this.setState({otherCheck: !this.state.otherCheck})}/> {stringConstants.OTHERTEXT}
                                    </label>
                                  </div>
                                  {this.state.otherCheck &&
                                      <div className="input-group">
                                        <input id="otherInput" name="otherInput" type="text" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.OTHERTEXTPLACEHOLDER}/>
                                      </div>
                                  }
                                </div>
                                {/*<div className="checkbox" style={{padding: '5px'}}>*/}
                                {/*  <label className="want_emails"><input type="checkbox" ref="financial_guide"/> Meeting with a*/}
                                {/*    financial guide</label>*/}
                                {/*</div>*/}
                                {/*<div className="checkbox" style={{padding: '5px'}}>*/}
                                {/*  <label className="want_emails"><input type="checkbox" ref="auto_loans"/> Auto loans*/}
                                {/*  </label>*/}
                                {/*</div>*/}
                                {/*<div className="checkbox" style={{padding: '5px'}}>*/}
                                {/*  <label className="want_emails"><input type="checkbox" ref="mortgages"/> Mortgages*/}
                                {/*  </label>*/}
                                {/*</div>*/}
                                {/*<div className="checkbox" style={{padding: '5px'}}>*/}
                                {/*  <label className="want_emails"><input type="checkbox" ref="credit_cards"/> Credit cards*/}
                                {/*  </label>*/}
                                {/*</div>*/}
                                {/*<div className="checkbox" style={{padding: '5px'}}>*/}
                                {/*  <label className="want_emails"><input type="checkbox" ref="retirement_savings"/> Retirement savings*/}
                                {/*  </label>*/}
                                {/*</div>*/}
                                {/*<div className="checkbox" style={{padding: '5px'}}>*/}
                                {/*  <label className="want_emails"><input type="checkbox" ref="business_services"/> Business services*/}
                                {/*  </label>*/}
                                {/*</div>*/}
                                {/*<div className="checkbox" style={{padding: '5px'}}>*/}
                                {/*  <label className="want_emails"><input type="checkbox" ref="rv_loans"/> RV Loans*/}
                                {/*  </label>*/}
                                {/*</div>*/}
                                {/*<div className="checkbox" style={{padding: '5px'}}>*/}
                                {/*  <label className="want_emails"><input type="checkbox" ref="other_checkbox" onClick={()=> this.setState({otherCheck: !this.state.otherCheck})}/> Other*/}
                                {/*  </label>*/}
                                {/*</div>*/}
                                {/*{this.state.otherCheck &&*/}
                                {/*  <div className="input-group">*/}
                                {/*    <input id="otherInput" name="otherInput" type="text" style={{fontFamily: "Oswald"}}*/}
                                {/*           className="form-control" onChange={this.handleChange} placeholder="Other Products"/>*/}
                                {/*  </div>*/}
                                {/*}*/}
                              </div>
                          }

                          <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play"
                                  id="submitButton"><strong>{stringConstants.SUBMITBUTTONTEXT}</strong></button>
                        </form>
                      </div>
                    </div>
                  </div>
                }
                <div style={{width: "100%", textAlign: "center"}}>
                  <img width="100%" src={sponsorLogo} alt="" style={{maxWidth: "380px"}}/>
                </div>
                <div className="mobilehide" style={{height: "100px", width: "1px"}}/>
              </div>
            </div>
            <Modal isOpen={(this.state.modal && videoURL)} style={{width: '90%'}} id="myModal" toggle={this.toggle} contentClassName="transparentBackground">
              <ReactPlayer config={{file: {attributes: {controlsList: 'nodownload', disablePictureInPicture: true}}}}
                           controls={true} pip={false} playing={this.state.modal} url={videoURL} playsinline={true}
                           muted={true} volume={0} onEnded={this.onEnded} width="100%" height="auto"/>
            </Modal>
          </>
      )
    }

    async handleSubmit(event){
      event.preventDefault();
      const formQuestions = this.state.formQuestions || [];
      const stringConstants = this.props.stringConstants || {};
      const tenantVariables = this.props.variables || {};
      // const zip = this.state.zip.trim();
      // const name = this.state.fullName.trim();
      // const phone = this.state.phone.trim();
      const timesubmitted = Date.now();
      // const receive_emails = this.refs.want_emails.checked;
      const dataObject = {};
      // let questionResponses = [];
      let somethingChecked = false;
      for(const i in formQuestions){
        let userResponse = false;
        // questionHeaders.push(formQuestions[i].text)
        if(this.refs[formQuestions[i].id].checked){
          somethingChecked = true;
          userResponse = true;
        }
        dataObject[formQuestions[i].id] = userResponse;
        // questionResponses.push(userResponse);
      }
      const other_checkbox = this.refs.other_checkbox.checked;
      const otherInput = this.state.otherInput.trim();
      // const financial_guide = this.refs.financial_guide.checked;
      // const auto_loans = this.refs.auto_loans.checked;
      // const retirement_savings = this.refs.retirement_savings.checked;
      // const mortgages = this.refs.mortgages.checked;
      // const credit_cards = this.refs.credit_cards.checked;
      // const business_services = this.refs.business_services.checked;
      // const rv_loans = this.refs.rv_loans.checked;
      if(!somethingChecked) {
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: "To submit you need to check at least one box",
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        });
        return
      } else if(other_checkbox && !otherInput){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: "If you check other you need to fill in the other input",
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        });
        return;
      }

      // if(!this.refs.agree_to_rules_regs.checked){
      //     confirmAlert({
      //       variables: this.props.variables,
      //       title: stringConstants.MISSINGINFOTEXT,
      //       message: stringConstants.NOAGREERULESANDREGS,
      //       buttons: [
      //         {
      //           label: stringConstants.OKTEXT,
      //         }
      //       ]
      //     });
      //     return;
      // }
      // if(zip.length === 0 || name.length === 0 || phone === 0){
      //   confirmAlert({
      //     variables: this.props.variables,
      //     title: stringConstants.MISSINGINFOTEXT,
      //     message: stringConstants.PLEASEFILLOUTTHEFORMTEXT,
      //     buttons: [
      //       {
      //         label: stringConstants.OKTEXT,
      //       }
      //     ]
      //   });
      //   return;
      // }
      const base64EncodedEmail = btoa(this.state.userEmail);
      // const vm = this;
      // // const formData = {name: name, phone: phone, zip: zip, receive_emails: receive_emails, timeSubmitted: timesubmitted};
      // const formData = {financial_guide: financial_guide || "",
      //   auto_loans: auto_loans || "",
      //   mortgages: mortgages || "",
      //   credit_cards: credit_cards || "",
      //   other_checkbox: other_checkbox || "",
      //   business_services: business_services || "",
      //   retirement_savings: retirement_savings || "",
      //   rv_loans: rv_loans || "",
      //   otherInput: otherInput || ""
      // };
      if(!base64EncodedEmail){
        return
      }

      await base.update(`users/`+ base64EncodedEmail, {
           data: {
                 other_checkbox: other_checkbox|| "",
                 otherInput: otherInput || ""
           },
           then(err){
             if(err){
               confirmAlert({
                 variables: tenantVariables,
                 title: stringConstants.ERRORTEXT,
                 message: err.message,
                 buttons: [
                   {
                     label: stringConstants.OKTEXT,
                   }
                 ]
               })
             } else {
               base.update(`users/`+ base64EncodedEmail + `/form_responses` , {
                 data: dataObject,
                 then(err){
                   if(!err){
                     document.getElementById('more-info-form').style.display = "none";
                     // document.getElementById('thanks-for-playing-section').style.display = "";
                   } else {
                     console.log(err);
                     confirmAlert({
                       variables: tenantVariables,
                       title: stringConstants.ERRORTEXT,
                       message: err.message,
                       buttons: [
                         {
                           label: stringConstants.OKTEXT,
                         }
                       ]
                     })
                   }
                 }
               });
             }
           }
      })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.questionAnswered && !this.state.questionAnswered){
        this.removeReactAlert();

        this.setState({
          startConfetti:false,
          immediateAnswersShow: false
        })
      }
    }

    render() {
      const tenantVariables = this.props.variables || {};
      const tenantRules = this.state.tenantRules || {};
      const stringConstants = this.props.stringConstants || {};
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      let rulesPopUpText = tenantRules.rulesPopUpText;
      let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
      const link = tenantRules.rulesAndRegsLink;
      const rulesShowInApp = tenantRules.rulesShowInApp || false;
      if(rulesShowInApp) {
        if(!rulesShowInAppPopUpText && rulesPopUpText){
          rulesShowInAppPopUpText = rulesPopUpText;
        }
        if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
          rulesShowInAppPopUpHeader = rulesPopUpHeader;
        }
      }
      if(!this.state.userEmail){
        let redirectString = "/login";
        let langague = "";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(window.location.pathname.split('/').length > 1){
          langague = "/" + splitWindowLocation[1]
          redirectString += langague
        }
        return (
            <Redirect to={redirectString} />
        )
      } else if(!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail)){
        this.logout();
      } else if(tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail)){
        this.logout();
      }
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      if(!verifiedAge){
        let urlToGo = "/age_gate";
        let langauge = "/";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(splitWindowLocation.length > 1){
          langauge += splitWindowLocation[1];
          urlToGo += langauge
        }
        return (
            <Redirect to={urlToGo} />
        )
      }
      let renderMainScreen = this.renderHoldingScreen();
      if(this.state.gameActive === true && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      }
      const backgroundImage = tenantVariables.backgroundImage || "";
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
            {this.state.startConfetti &&
              <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>
            }
            <div className="flex-content-container-home">
              <div className="intro-container-home" style={{margin: "auto", width: "100%"}}>
                <div className="grid-wrapper">
                  <div className="flex-header-home" style={{margin:"auto",maxWidth: 800}}>
                    <div style={{visibility: rulesShowInApp? "visible":"hidden", margin:10}}>
                      <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                    </div>
                    <div style={{margin:10, display: this.props.passedEmail && "none"}}>
                      <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.LOGOUTTEXT}</button>
                    </div>
                  </div>
                  {renderMainScreen}
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                    {rulesShowInAppPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
            {/*<Modal isOpen={this.state.modal} style={{width: '90%'}} id="myModal" toggle={this.toggle}>*/}
            {/*  <ReactPlayer config={{file: { attributes : { controlsList: 'nodownload', disablePictureInPicture:true} } }} controls={true} pip={false} playing={this.state.modal} url={"https://www.youtube.com/embed/0Y5U2zkRW9g"} playsinline={true} muted={true} volume={0} onEnded={this.onEnded} width="100%" height="auto"/>*/}
            {/*</Modal>*/}
          </div>
      )
    }
}

export default Main_App;
